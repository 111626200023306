import React from 'react'
import styles from './careers.module.sass'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Career from '../components/career'

const CareersPage = ({ data }) => (
   <Layout>
      <SEO title="Careers" />
      <div className={['page', styles.page__services].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Careers
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               We’re looking for dedicated, passionate, ambitious<br></br>{' '}
               people who embrace challenges to join our human-centric team.
               <br></br>Apply to our available open positions.
            </p>
         </div>
         <div className={styles.services}>
            {/* <Career
               name="Certified Public Accountant"
               description="Our related firm Sella & Martinic, LLC*-a CPA firm that focuses on tax accounting—is seeking a motivated Certified Public Accountant to join their team! Applicant must be able to work in person at their Rockville, MD office."
            /> */}
            {data.allContentfulCareer &&
               data.allContentfulCareer.edges.map(({ node }, index) => (
                  <Career
                     key={index}
                     name={node.name}
                     description={
                        node.shortDescription.childMarkdownRemark.html
                     }
                     slug={node.slug}
                  />
               ))}
         </div>
      </div>
   </Layout>
)

export default CareersPage

export const query = graphql`
   query {
      allContentfulCareer {
         edges {
            node {
               name
               slug
               company
               shortDescription {
                  childMarkdownRemark {
                     html
                     timeToRead
                  }
               }
               longDescription {
                  raw
               }
            }
         }
      }
   }
`
