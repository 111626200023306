import React, { Component } from 'react'
import styles from './career.module.sass'

import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

class Service extends Component {
   constructor(props) {
      super(props)

      this.setState = this.setState.bind(this)
   }

   popup(title, content, type) {
      Swal.fire(`${title}`, `${content}`, `${type}`)
   }

   render() {
      return (
         <div className={styles.career}>
            <div className={styles.career__caption}>
               <h3 className={styles.career__name}>{this.props.name}</h3>
               <div
                  className={styles.career__description}
                  dangerouslySetInnerHTML={{
                     __html: this.props.description,
                  }}></div>
               <div className={styles.footer}>
                  <Link
                     to={'/career/' + encodeURIComponent(this.props.slug)}
                     className="button button--secondary button--rounded button--small button--has-icon">
                     READ MORE <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
            </div>
         </div>
      )
   }
}

export default Service
